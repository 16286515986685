import DspConfig from './DspConfigModel/DspConfig'
import { toMergeCommonCustomProdMgmt, toMergeCommonMarkupModule } from './commonDspConfig'
import { KpiValue } from '../../types/brief_enum'

export default new DspConfig({
  headers: [
    { text: 'info', value: 'group_name', sortable: false, onlyHeader: true },
    { text: 'Group Name', value: 'group_name', class: 'td-data-table-left' },
    { text: 'Member / Advertiser', value: 'advertiser_id', sortable: false, class: 'td-data-table-center' },
    { text: 'IO/LI/CA', value: '', sortable: false, class: 'td-data-table-center' },
    { text: 'Objectives', value: '', sortable: false, class: 'td-data-table-left' },
    { text: 'Constraint_IO', value: '', sortable: false, class: 'td-data-table-left' }
  ],
  client_value: 'member_id',
  ioField: 'optimize_insertion_orders',
  newIoField: 'insertion_order_id',
  ioFieldLabel: 'Insertion order Id',
  ioFieldAlert: 'insertion_order_id',
  adLabel: 'Advertiser id',
  adField: 'advertiser_id',
  matchingFields: ['matching_li'],
  specialFields: [],
  kpiToOptimizeOptions: [
    KpiValue.CPC,
    KpiValue.CTR,
    KpiValue.VTR,
    KpiValue.CPCV,
    KpiValue.V_CPM,
    KpiValue.CPA,
    KpiValue.CPA_PC,
    KpiValue.CPM,
    KpiValue.authentic_attention_index,
    KpiValue.reach,
    KpiValue.viewable_time,
    KpiValue.ViewTR,
    KpiValue.LTV,
    KpiValue.total_engagement_index,
    KpiValue.total_exposure_index
  ],
  constraintsFormConfig: [
    { label: 'Expected margin daily (%)', computed: 'expectedMargin', isPercentage: true, className: 'selenium-margin-daily' },
    { label: 'Highest allowed CPM', computed: 'highestAllowedCPM', isPercentage: false, className: 'selenium-max-cpm' },
    { label: 'Lowest allowed CPM', computed: 'lowestAllowedCPM', isPercentage: false, className: 'selenium-min-cpm' },
    { label: 'Lowest allowed view rate (%)', computed: 'lowestAllowedViewRate', isPercentage: true, className: 'selenium-view-rate' },
    { label: 'Lowest allowed completion rate (%)', computed: 'lowestAllowedCompletionRate', isPercentage: true, hint: 'Not yet detected by our alerting system', className: 'selenium-cRate' },
    { label: 'KPI Target (DSP)', computed: 'proxyTargetKpiValue', isPercentage: false, isDouble: true, doubleComputed: 'proxyTargetForOptimizedKpi', className: 'selenium-target-kpi' },
    { label: 'KPI Target (3rd party)', computed: 'proxyTargetKpiValue3rdParty', isPercentage: false }
  ],
  overwritingFields: {
    normal: [
      { label: 'Budget',
        isBtnToggle: [
          { label: 'global', value: 'remove_daily_budget_global' },
          { label: 'off', value: '' },
          { label: 'campaign', value: 'remove_daily_budget_campaigns_only' }
        ],
        computedProperty: 'overwriteBudget'
      },
      { label: 'Time parting', value: 'remove_time_parting' },
      { label: 'Overwrite Creative Selection', value: 'overwrite_creative_selection' },
      { label: 'Force Li Asap daily pacing', value: 'force_li_daily_pacing_asap', tooltip: 'Allow to force Li asap daily pacing when there is a daily budget at Li level' }
    ],
    advanced: [
      { label: 'Fold position', value: 'remove_fold_position' },
      { label: 'Trusted inventories', value: 'keep_trusted_inventory', reverseBoolean: true },
      { label: 'Min viewability', value: 'remove_min_viz' },
      { label: 'Min completion', value: 'remove_min_crate' }
    ]
  },
  toMerge: [
    { prop: ['member_id'] },
    { prop: ['advertiser_id'] },
    { prop: ['obj_watcher', 'margin', 'value'] },
    { prop: ['obj_watcher', 'max_CPM', 'value'] },
    { prop: ['obj_watcher', 'CPA', 'value'] },
    { prop: ['obj_watcher', 'min_CPM', 'value'] },
    { prop: ['obj_watcher', 'min_viz', 'value'] },
    { prop: ['obj_watcher', 'min_crate', 'value'] },
    { prop: ['obj_watcher', 'margin', 'sensibility'] },
    { prop: ['obj_watcher', 'max_CPM', 'sensibility'] },
    { prop: ['obj_watcher', 'CPA', 'sensibility'] },
    { prop: ['obj_watcher', 'min_CPM', 'sensibility'] },
    { prop: ['obj_watcher', 'min_viz', 'sensibility'] },
    { prop: ['obj_watcher', 'min_crate', 'sensibility'] },
    { prop: ['obj_watcher', 'CPA', 'pixel_id'] },
    { prop: ['obj_watcher', 'true_KPI_to_optimize', 'value'] },
    { prop: ['obj_watcher', 'true_KPI_to_optimize', 'value_3rd_party'] },
    { prop: ['obj_watcher', 'true_KPI_to_optimize', 'KPI'] },
    { prop: ['obj_watcher', 'KPI_to_optimize', 'value'] },
    { prop: ['obj_watcher', 'KPI_to_optimize', 'value_3rd_party'] },
    { prop: ['obj_watcher', 'KPI_to_optimize', 'KPI'] },
    { prop: ['true_conversion_funnel_pixel_ids'] },
    { prop: ['conversion_funnel_pixel_ids'] },
    { prop: ['remove_frequency_global'] },
    { prop: ['remove_frequency_li_ca_only'] },
    { prop: ['remove_time_parting'] },
    { prop: ['remove_min_viz'] },
    { prop: ['remove_min_crate'] },
    { prop: ['remove_daily_budget_global'] },
    { prop: ['remove_daily_budget_campaigns_only'] },
    { prop: ['keep_trusted_inventory'] },
    { prop: ['overwrite_creative_selection'] },
    { prop: ['remove_fold_position'] },
    { prop: ['opti_auto'] },
    { prop: ['otto_min_viz'] },
    { prop: ['true_KPI_to_optimize'] },
    { prop: ['KPI_to_optimize'] },
    { prop: ['objective', 'true_PV_window'] },
    { prop: ['objective', 'true_PC_window'] },
    { prop: ['objective', 'PV_window'] },
    { prop: ['objective', 'PC_window'] },
    { prop: ['constraints_io', 'max_A'] },
    { prop: ['constraints_io', 'max_cutoff'] },
    { prop: ['constraints_io', 'mode'] },
    { prop: ['constraints_io', 'margin_daily'] },
    { prop: ['constraints_io', 'max_CPM'] },
    { prop: ['constraints_io', 'min_CPM'] },
    { prop: ['matching_li'] },
    { prop: ['matching_cp'] },
    { prop: ['status'] },
    { prop: ['true_conv_measurement_tool'] },
    { prop: ['conv_measurement_tool'] },
    { prop: ['use_opti_ratio_surcouche'] },
    { prop: ['constraints_io', 'force_min_budget'] },
    { prop: ['max_frequency'] },
    { prop: ['overwrite_frequency'] },
    { prop: ['revenue_type'] },
    { prop: ['force_li_daily_pacing_asap'] },
    ...toMergeCommonMarkupModule,
    ...toMergeCommonCustomProdMgmt,
    { prop: ['strat_lvl'] },
    { prop: ['retry'] },
    { prop: ['fast_retry'] },
    { prop: ['reactivate_otto'] },
    { prop: ['waterfall_priority'] },
    { prop: ['KPI_rolling_period'] },
    { prop: ['true_pivot_variable'] },
    { prop: ['p3_obj_watcher'] },
    { prop: ['p3_obj_watcher_alpha'] },
    { prop: ['p3_obj_watcher_pivot_variables'] }
  ]
})
