class UserModel {
  constructor (data) {
    this.mail = data.mail
    this.dvMail = data.dv_mail
    this.isDebugger = data.isDebugger
    this.isSettupper = data.isSettupper
    this.isAdminAndFinance = data.isAdminAndFinance
    this.isAm = data.isAm
    this.name = data.name
    this.userId = data.user_id
    this.isSale = data.isSale
  }
}

export default UserModel
