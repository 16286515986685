import {
  userHexColor,
  safeGet,
  safeSet,
  isoDateToDate,
  capitalize,
  str2hash,
  transformIsoDate,
  explodeDateString,
  formatDate,
  dateToStringYMD,
  objectIsEmpty,
  isEqual,
  sliceObject,
  detectDuplicateInArray,
  camelCaseToString,
  isNotNullOrUndefined,
  roundPercentage,
  isSetAndNotEmpty, whichFieldAreNotEqual,
  removeFromObject, removeEmptyFieldFromObject, removeNullAndUndefinedValueFromObject, removeElementFromDom
} from './commonUtils'
import { getClientValue, getIoField, getNewIoField } from './instructionsUtils'

const VueUtils = {}

VueUtils.install = function (Vue) {
  /**
  * slice object
  * @param obj {object} the object to slice
  * @param start {number}
  * @param end {number}
  * @returns {array} the sliced object
  */
  Vue.prototype.$sliceObject = sliceObject

  /**
   * @param object {object}
   * @param test {function} the function to evaluate if the value is removed from the object
   */
  Vue.prototype.$removeFromObject = removeFromObject

  /**
   * recursive function for removing empty , null or undefined values from a object
   * if the object contain reference to object who contain only empty, null
   * and undefined values, the object is deleted
   * @param {object} object
   */
  Vue.prototype.$removeEmptyFieldFromObject = removeEmptyFieldFromObject

  Vue.prototype.$removeNullAndUndefinedValueFromObject = removeNullAndUndefinedValueFromObject

  Vue.prototype.$removeEmptyFieldAndEmptyObjectFromObject = function (object) {
    let testEmptyAndEmptyObject = function (toTest) {
      return toTest === undefined || toTest === '' || toTest === null || (typeof toTest === 'object' && objectIsEmpty(toTest))
    }

    for (let i in object) {
      if (testEmptyAndEmptyObject(object[i])) {
        delete object[i]
      } else if (typeof object[i] === 'object') {
        Vue.prototype.$removeEmptyFieldAndEmptyObjectFromObject(object[i])
        if (objectIsEmpty(object[i])) {
          delete object[i]
        }
      }
    }
  }

  /**
   * @param id {String} the id of the element to remove
   * @param removeParent {Boolean} if the parent node need to be removed too
   */
  Vue.prototype.$removeElementFromDom = removeElementFromDom
  /**
   * is the index in array ?
   * @param array {Array}
   * @param index {Number}
   * @returns {Boolean}
   */
  Vue.prototype.$inArray = function (array, index) {
    return array[index] !== undefined
  }

  /**
   * test if 2 Object (Object or Array) are equal
   * thx to ChrisFerdinandi => https://gomakethings.com/check-if-two-arrays-or-objects-are-equal-with-javascript/
   * @param {Object} value
   * @param {Object} other
   * @returns {Boolean} is the Object are equal ?
   */
  Vue.prototype.$isEqual = isEqual

  Vue.prototype.$whichFieldAreNotEqual = whichFieldAreNotEqual

  Vue.prototype.$isNotNullOrUndefined = function (value) {
    return isNotNullOrUndefined(value)
  }

  Vue.prototype.$isNullOrUndefined = function (value) {
    return value === undefined || value === null
  }

  Vue.prototype.$issetAndNotEmpty = function (value) {
    return isSetAndNotEmpty(value)
  }

  /**
   *  return a array with all the duplicate of the param 'array'
   * @param array {Array}
   * @returns {Array}
   */
  Vue.prototype.$detectDuplicateInArray = detectDuplicateInArray

  /***
   * Transform a iso date to a displayable string
   * @param isoDate {String} a string date with a form like => 2019-05-20T09:53:35.004302+00:00
   * @param precise {String} (default true) set it to false if you just want year month and day
   * @returns {String}
   */
  Vue.prototype.$transformIsoDate = transformIsoDate

  Vue.prototype.$formatDate = formatDate

  /**
   * @param {Date} date
   * @returns {Object}
   */
  Vue.prototype.$explodeDateString = explodeDateString

  /**
   * sort per is_active. If the 2 elements are equal, sort by insertion date
   */
  Vue.prototype.$sortByIsActive = function (a, b) {
    if (a.is_active && !b.is_active) {
      return -1
    }

    if (!a.is_active && b.is_active) {
      return 1
    }

    return Vue.prototype.$sortByInsertionDate(a, b)
  }

  Vue.prototype.$sortByInsertionDate = function (a, b) {
    if (Vue.prototype.$testDate(a.insertion_date) && !Vue.prototype.$testDate(b.insertion_date)) {
      return -1
    }

    if (Vue.prototype.$testDate(b.insertion_date) && !Vue.prototype.$testDate(a.insertion_date)) {
      return 1
    }

    return new Date(b.insertion_date) - new Date(a.insertion_date)
  }

  /**
   * thx to => Borgar (https://stackoverflow.com/questions/1353684/detecting-an-invalid-date-date-instance-in-javascript)
   */
  Vue.prototype.$testDate = function (d) {
    if (Object.prototype.toString.call(d) === '[object Date]') {
      // it is a date
      if (isNaN(d.getTime())) { // d.valueOf() could also work
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  Vue.prototype.$safeGet = safeGet
  Vue.prototype.$safeSet = safeSet

  Vue.prototype.$nestedSet = function (object, str, value) {
    let exploded = str.split('.')
    let currentObject = object
    let field
    for (let i in exploded) {
      if (!exploded.hasOwnProperty(i)) {
        continue
      }
      field = exploded[i]

      if (currentObject[field] === undefined || currentObject[field] === null) {
        currentObject[field] = {}
        this.$set(currentObject, field, {})
      }
      if (Number(i) !== exploded.length - 1) {
        currentObject = currentObject[field]
      }
    }

    currentObject[field] = value
  }

  /**
   * generate a link to the overlay ('surcouche') with the id and the dsp
   * return false if one of the args isn't conform
   * @param {string|number} id
   * @param {string} dsp
   * @returns {string|boolean}
   */
  Vue.prototype.$generateLinkToOverlay = function (id, dsp) {
    if (id === undefined || dsp === undefined || typeof dsp !== 'string') {
      return false
    }
    return `${this.$_FRONT_HOST}/${dsp.toLowerCase()}?q_search=${id}`
  }

  Vue.prototype.$generateLinkDatastore = function (id, dsp) {
    const datastoreConfig = {
      [this.$APPNEXUS]: { kind: 'appnexus_instructions_optimization', config: 'config_appnexus' },
      [this.$DBM]: { kind: 'dbm_instructions_optimization', config: 'config_dbm' },
      [this.$MEDIAMATH]: { kind: 'mediamath_instructions_optimization', config: 'config_mediamath' },
      [this.$THETRADEDESK]: { kind: 'thetradedesk_instructions_optimization', config: 'config_thetradedesk' }
    }

    let project = ''

    if (this.$_ENV === 'production') {
      project = 'internal-interface'
    } else {
      project = 'webinterfacetest-1'
    }

    let config = datastoreConfig[dsp.toLowerCase()]
    return `https://console.cloud.google.com/datastore/entities;kind=${config.kind};ns=${config.config}/edit;key=15%2F${config.config}%257C34%2F${config.kind}%257C19%2Fid%253A${id}?project=${project}`
  }

  Vue.prototype.$getClientValue = getClientValue

  Vue.prototype.$roundPercentage = function (ratio) {
    return roundPercentage(ratio)
  }

  Vue.prototype.$getIoField = getIoField

  Vue.prototype.$replaceComma = function (event) {
    event.target.value = event.target.value.replace(',', '.')
  }

  Vue.prototype.$str2Hash = str2hash
  Vue.prototype.$userHexColor = userHexColor
  Vue.prototype.$dateToStringYMD = dateToStringYMD
  Vue.prototype.$camelCaseToString = camelCaseToString
  Vue.prototype.$capitalize = capitalize
  Vue.prototype.$isoDateToDate = isoDateToDate
}

export default VueUtils
