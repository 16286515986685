import DspConfig from './DspConfigModel/DspConfig'
import { toMergeCommonMarkupModule, toMergeCommonCustomProdMgmt } from './commonDspConfig'
import { KpiValue } from '../../types/brief_enum'

export default new DspConfig({
  headers: [
    { text: 'info', value: 'group_name', sortable: false, onlyHeader: true },
    { text: 'Group Name', value: 'group_name', class: 'td-data-table-left' },
    { text: 'Member / Advertiser', value: 'advertiser_id', sortable: false, class: 'td-data-table-center' },
    { text: 'IO/LI/CA', value: '', sortable: false, class: 'td-data-table-center' },
    { text: 'Objectives', value: '', sortable: false, class: 'td-data-table-left' }
  ],
  client_value: 'partner_id',
  ioField: 'optimize_insertion_orders',
  newIoField: 'insertion_order_id',
  ioFieldLabel: 'Insertion order Id',
  ioFieldAlert: 'insertion_order_id',
  adLabel: 'Advertiser id',
  adField: 'advertiser_id',
  matchingFields: [],
  specialFields: [],
  kpiToOptimizeOptions: [
    KpiValue.CPVideoView,
    KpiValue.CPMbumper,
    KpiValue.reach,
    KpiValue.ViewTR,
    KpiValue.CPA,
    KpiValue.LTV,
    KpiValue.total_engagement_index,
    KpiValue.total_exposure_index
  ],
  constraintsFormConfig: [
    { label: 'KPI Target (DSP)', computed: 'proxyTargetKpiValue', isPercentage: false, isDouble: true, doubleComputed: 'proxyTargetForOptimizedKpi', className: 'selenium-target-kpi' },
    { label: 'KPI Target (3rd party)', computed: 'proxyTargetKpiValue3rdParty', isPercentage: false }
  ],
  overwritingFields: {
    normal: [
      // { label: 'Overwrite frequency', value: 'overwrite_frequency' }
      { label: 'Overwrite li budget', value: 'overwrite_li_budget' },
      { label: 'Automatically remove deprecated url', value: 'automatically_remove_deprecated_url' },
      { label: 'Overwrite bid adjustments on device', value: 'overwrite_bid_adjustments_on_device', tooltip: 'AI has freedom to modify bid adjustment on devices at Ad-Group Level <u><b>unless</b></u> bid adjustment is equal to Decrease by 100%.' },
      { label: 'Time parting', value: 'remove_time_parting' },
      { label: 'Allow switch IO pacing to ASAP', value: 'allow_switch_io_pacing' }
    ],
    advanced: [
    ]
  },
  toMerge: [
    { prop: ['partner_id'] },
    { prop: ['advertiser_id'] },
    { prop: ['opti_auto'] },
    { prop: ['constraints_io', 'max_A'] },
    { prop: ['overwrite_frequency'] },
    { prop: ['overwrite_frequency_target'] },
    { prop: ['status'] },
    { prop: ['max_frequency'] },
    { prop: ['target_frequency'] },
    { prop: ['true_KPI_to_optimize'] },
    { prop: ['KPI_to_optimize'] },
    { prop: ['obj_watcher', 'true_KPI_to_optimize', 'value'] },
    { prop: ['obj_watcher', 'true_KPI_to_optimize', 'value_3rd_party'] },
    { prop: ['obj_watcher', 'true_KPI_to_optimize', 'KPI'] },
    { prop: ['obj_watcher', 'KPI_to_optimize', 'value'] },
    { prop: ['obj_watcher', 'KPI_to_optimize', 'value_3rd_party'] },
    { prop: ['obj_watcher', 'KPI_to_optimize', 'KPI'] },
    { prop: ['true_conv_measurement_tool'] },
    { prop: ['conv_measurement_tool'] },
    { prop: ['use_opti_ratio_surcouche'] },
    { prop: ['constraints_io', 'force_min_budget'] },
    { prop: ['constraints_io', 'max_cutoff'] },
    { prop: ['overwrite_li_budget'] },
    { prop: ['automatically_remove_deprecated_url'] },
    { prop: ['overwrite_bid_adjustments_on_device'] },
    { prop: ['revenue_type'] },
    { prop: ['overwrite_unknown_demographic'] },
    ...toMergeCommonMarkupModule,
    ...toMergeCommonCustomProdMgmt,
    { prop: ['strat_lvl'] },
    { prop: ['remove_time_parting'] },
    { prop: ['fast_retry'] },
    { prop: ['reactivate_otto'] },
    { prop: ['waterfall_priority'] },
    { prop: ['KPI_rolling_period'] },
    { prop: ['true_pivot_variable'] },
    { prop: ['allow_switch_io_pacing'] },
    { prop: ['constraints_io', 'min_A'] }
  ]
})
