import axios from 'axios'
import { getAxiosJwtInstance } from './headersHelper'

// eslint-disable-next-line no-var
var isCalledByNode = false
// detect if the module is called by node
if (typeof window === 'undefined') {
  // For allow to test the convertToFormData method
  // if this module is called by node in command line,
  // we need to require the form-data object (who exist natively in the browser but not in node)
  // (26/09/2019, the form-data module for node is only installed in dev, install it for all env if necessary) => https://www.npmjs.com/package/form-data
  // eslint-disable-next-line no-var
  var NodeFormData = require('form-data')
  isCalledByNode = true
  // recreate the 'get' method, not available in node FormData
  NodeFormData.prototype.get = function (key) {
    // the boundary in _streams have 2 '-' less than _boundary
    let fKey = `--${this._boundary}\r\nContent-Disposition: form-data; name="${key}"\r\n\r\n`
    let index = this._streams.indexOf(fKey)

    if (index === -1) {
      return undefined
    }
    return this._streams[index + 1]
  }
}

// some ref :
// handling errors : https://github.com/axios/axios#handling-errors

// TODO : to refacto : post / postRaw put / putRaw

const headers = {
  formData: { headers: { 'Content-Type': 'multipart/form-data' } },
  json: { headers: { 'Content-Type': 'application/json' } }
}

/**
 * Base Class for create class for call Api
 *
 * Like a Abstract Class, but you can use it alone (not recommend)
 *
 * 1/
 *
 * // You need to provide a options like that :
 *
 * const apiConfig = {
 *  server: process.env.VUE_APP_API_HOST,
 *  calls: {
 *   alerts: {
 *     get: '/alerts',
 *     put: '/alerts'
 *   },
 *   alert: {
 *     get: '/alert',
 *     put: '/alert'
 *   },
 * }
 *
 * 2/
 *
 * // Create the class
 *
 * const jwt = <a_valid_jwt>
 * const apiCaller = new ApiCaller(apiConfig, jwt)
 *
 * 3/
 *
 * // call one of the configured route
 *
 * apiCaller.get('alerts')
 *
 * Very easy !
 *
 * See scibidsApiCaller.js for sample of extended class, the best solution, allow you to call yet more easily your api !
 *
 * @author Quentin Gary
 * @copyright Scibids
 */
export class ApiCaller {
  /**
   * @param {object} options
   * @param {string|null} jwt
   * @constructor
   */
  constructor (options, jwt = null) {
    this.calls = options.calls
    this.server = options.server
    this.jwt = jwt

    /**
     * the list of axios cancel token
     * @type {Object}
     */
    this.cancelTokens = {}
  }

  /**
   * @param {string} endPoint
   * @returns {CancelToken || null} return null if the cancelToken doesn't exist
   */
  getCancelToken (endPoint) {
    return this.cancelTokens[endPoint] !== undefined ? this.cancelTokens[endPoint].token : null
  }

  setJwt (jwt) {
    this.jwt = jwt
  }

  convertToFormData (data) {
    let formData

    if (isCalledByNode) {
      formData = new NodeFormData()
    } else {
      formData = new FormData()
    }

    for (let key in data) {
      if (!data.hasOwnProperty(key)) {
        continue
      }
      if (typeof data[key] === 'object') {
        formData.append(key, JSON.stringify(data[key]))
      } else {
        formData.append(key, data[key])
      }
    }
    return formData
  }

  /**
   * @param {String} endPoint the end point defined in the config
   * @param {object} data the data to send in param
   * @param elementId
   * @param {string|number|null} replaceId  to user if config endpoint path contain <replace_id>
   * @param {object} replaceIdMulti  see createCall function for more info
   * @param {boolean} cancel set it to false if sending a Axios Cancel Token isn't necessary
   * @returns {Promise<T>}
   */
  post (endPoint, data, elementId = null, replaceId = null, replaceIdMulti = null,
    cancel = false) {
    this.checkEndPoint(endPoint, 'The endpoint must be defined in the config if you want to use the function \'post\'.')

    let config = {}

    if (cancel) {
      // call is cancelled in case of a previous call was not finished
      this.cancelCall(endPoint)
      this.createCancelToken(endPoint)
      config.cancelToken = this.getCancelToken(endPoint)
    }

    return getAxiosJwtInstance(this.jwt, false, true)
      .post(this.createCall(endPoint, 'post', elementId, data, replaceId, replaceIdMulti), this.convertToFormData(data), config)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.warn(error)
        return error
      })
  }

  /**
   * @param {String} endPoint the end point defined in the config
   * @param {object} data the data to send in param
   * @param elementId
   * @param {string|number|null} replaceId  to user if config endpoint path contain <replace_id>
   * @param {object} replaceIdMulti  see createCall function for more info
   * @param {boolean} cancel set it to false if sending a Axios Cancel Token isn't necessary
   * @returns {Promise<T>}
   */
  postRaw (endPoint, data, elementId = null, replaceId = null, replaceIdMulti = null, cancel = false) {
    this.checkEndPoint(endPoint, 'The endpoint must be defined in the config if you want to use the function \'post\'.')

    let config = {}

    if (cancel) {
      // call is cancelled in case of a previous call was not finished
      this.cancelCall(endPoint)
      this.createCancelToken(endPoint)
      config.cancelToken = this.getCancelToken(endPoint)
    }

    return getAxiosJwtInstance(this.jwt, true).post(this.createCall(endPoint, 'post', elementId, data, replaceId, replaceIdMulti), data, config)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.warn(error)
        return error
      })
  }

  /**
   * create simple query for a endPoint defined in the config
   * @param {String} endPoint the end point defined in the config
   * @param {object} data the data to send in param
   * @param elementId
   * @param {boolean} cancel set it to false if sending a Axios Cancel Token isn't necessary
   * @param {string|number|null} replaceId  to user if config endpoint path contain <replace_id>
   * @param {object} replaceIdMulti  see createCall function for more info
   * @returns {Promise<T>} the result of the query
   */
  get (endPoint, data = null, elementId = null, cancel = true, replaceId = null, replaceIdMulti = null) {
    this.checkEndPoint(endPoint, 'The endpoint must be defined in the config if you want to use the function \'get\'.')

    let config = {}

    if (cancel) {
      // call is cancelled in case of a previous call was not finished
      this.cancelCall(endPoint)
      this.createCancelToken(endPoint)
      config.cancelToken = this.getCancelToken(endPoint)
    }

    return getAxiosJwtInstance(this.jwt)
      .get(this.createCall(endPoint, 'get', elementId, data, replaceId, replaceIdMulti), config)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Request cancelled : ', error.message)
        }
        console.warn(error)
        return error
      })
  }

  /**
   * proxy for get with replaceIdMulti
   * @param {String} endPoint the end point defined in the config
   * @param {object} replaceIdMulti  see createCall function for more info
   * @param {boolean} cancel set it to false if sending a Axios Cancel Token isn't necessary
   * @param {object} data the data to send in param
   * @returns {Promise<T>} the result of the query
   */
  getObj (endPoint, replaceIdMulti, cancel = true, data = null) {
    return this.get(endPoint, data, null, cancel, null, replaceIdMulti)
  }

  /**
   *
   * @param {String} endPoint the end point defined in the config
   * @param {object} data the data to send in param
   * @param {number|string} elementId the addedId of createCall
   * @param replaceId {string|number|null} to user if config endpoint path contain <replace_id>
   * @param replaceIdMulti {object} see createCall function for more info
   * @returns {Promise<R>}
   */
  delete (endPoint, data = null, elementId = null, replaceId = null, replaceIdMulti = null) {
    this.checkEndPoint(endPoint, 'The endpoint must be defined in the config if you want to use the function \'delete\'.')

    return getAxiosJwtInstance(this.jwt).delete(this.createCall(endPoint, 'delete', elementId, data, replaceId, replaceIdMulti))
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.warn(error)
        return error
      })
  }
  /**
   *
   * @param {String} endPoint the end point defined in the config
   * @param {object} data the data to send in param
   * @param {number|string|null} elementId the addedId of createCall
   * @param {string|number|null} replaceId  to user if config endpoint path contain <replace_id>
   * @param {object} replaceIdMulti  see createCall function for more info
   * @returns {Promise<T>}
   */
  put (endPoint, data, elementId = null, replaceId = null, replaceIdMulti = null) {
    this.checkEndPoint(endPoint, 'The endpoint must be defined in the config if you want to use the function \'put\'.')

    return getAxiosJwtInstance(this.jwt, false, true)
      .put(this.createCall(endPoint, 'put', elementId, null, replaceId, replaceIdMulti), this.convertToFormData(data))
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.warn(error)
        return error
      })
  }

  /**
   *
   * @param {String} endPoint the end point defined in the config
   * @param {object} data the data to send in param
   * @param {number|string|null} elementId the addedId of createCall
   * @param {string|number|null} replaceId  to use if config endpoint path contain <replace_id>
   * @param {object} replaceIdMulti  see createCall function for more info
   * @returns {Promise<T>}
   */
  putRaw (endPoint, data, elementId = null, replaceId = null, replaceIdMulti = null) {
    this.checkEndPoint(endPoint, 'The endpoint must be defined in the config if you want to use the function \'post\'.')
    return getAxiosJwtInstance(this.jwt, true).put(this.createCall(endPoint, 'put', elementId, null, replaceId), data)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.warn(error)
        return error
      })
  }
  /**
   * create string for calling the endpoint
   * @param {string} entity
   * @param {string} callType must be get | put | post
   * @param {number|string|null} addedId the id added to the path ex : server/api/endPoint/<addedId>?arg1=2&arg3=toto
   * @param {object} data  the data added to the end of the point ex : server/api/endPoint?<key1>=<value1>&<key2>=<value2>
   * @param {number|string|null} replaceId  addedId the id added to the path ex : server/api/endPoint/<replaceId>/subendpoint?arg1=2&arg3=toto
   * @param {object} replaceIdMulti  for endpoint with multiple replaceId. The key is the string in api config who will be replace
   * ex : for apiConfig = '/route/<first_arg>/<second_arg>', with a replaceIdMulti = {first_arg: 'fee', second_arg: 'foo'}, the result will be : '/route/fee/foo'
   * @returns {string}
   */
  createCall (entity, callType, addedId = null, data = null, replaceId = null, replaceIdMulti = null) {
    let call = this.generateBaseCall(entity, callType)

    if (addedId !== null) {
      call += `/${addedId}`
    }

    if (replaceId !== null) {
      call = call.replace('<replace_id>', replaceId)
    }

    if (replaceIdMulti !== null && typeof replaceIdMulti === 'object') {
      for (let key in replaceIdMulti) {
        call = call.replace(`<${key}>`, replaceIdMulti[key])
      }
    }

    if (['get', 'delete'].indexOf(callType) !== -1 && data !== null) {
      call += '?'
    }

    if (['get', 'delete'].indexOf(callType) !== -1 && data !== null) {
      call = this.addDataToCall(data, call)
    }

    return call
  }

  /**
   * @param {string} endPoint the call to cancel
   * @return {boolean} if the call has been right cancelled
   */
  cancelCall (endPoint) {
    this.checkEndPoint(endPoint)

    if (this.cancelTokens[endPoint] && this.cancelTokens[endPoint] !== null) {
      this.cancelTokens[endPoint].cancel(`Call cancelled for the endpoint ${endPoint}`)
      delete this.cancelTokens[endPoint]
      return true
    }

    return false
  }

  /**
   * create a cancel token
   * @param {string} endPoint
   */
  createCancelToken (endPoint) {
    this.cancelTokens[endPoint] = axios.CancelToken.source()
  }

  /**
   * check if the endPoint is setted in the config
   * @param {string} endPoint the endpoint to check
   * @returns {boolean}
   */
  isEndPointInConfig (endPoint) {
    let endPointsList = Object.keys(this.calls)
    return endPointsList.indexOf(endPoint) !== -1
  }

  addDataToCall (data, call) {
    let i = 0
    for (let key in data) {
      if (!data.hasOwnProperty(key)) {
        continue
      }
      if (i !== 0) {
        call += '&'
      }

      if (typeof data[key] === 'object') {
        call += `${key}=${JSON.stringify(data[key])}`
      } else {
        call += `${key}=${data[key]}`
      }
      i++
    }

    return call
  }

  generateBaseCall (endPoint, callType) {
    this.checkEndPoint(endPoint)
    return `${this.server}${this.calls[endPoint][callType]}`
  }

  checkEndPoint (endPoint, msg = null) {
    if (!this.isEndPointInConfig(endPoint)) {
      throw new EndPointNotInConfig({ message: msg })
    }
  }

  /**
   * @param response
   * @param cancelAsError {boolean} if set to true, a cancel will be considered as a error
   * @returns {*|response|{verifier}|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|boolean}
   */
  isResponseError (response, cancelAsError = false) {
    if (cancelAsError) {
      return response.response || [200, 201, 202, 204].indexOf(response.status) === -1 || axios.isCancel(response)
    }
    return (response.response || [200, 201, 202, 204].indexOf(response.status) === -1) && !axios.isCancel(response)
  }

  isCancelError (response) {
    return axios.isCancel(response)
  }

  isApiResponseStatusUnauthorized (response) {
    return response.response !== undefined && response.response.data !== undefined && response.response.data.status === 401
  }
}

export class EndPointNotInConfig extends Error {
  constructor (...args) {
    super(...args)
    Error.captureStackTrace(this, EndPointNotInConfig)
    this.name = 'EndPointNotInConfig'
    this.message = 'The endpoint must be defined in the config.'
    if (args[0].message !== undefined && args[0].message !== null) {
      this.message = args[0].message
    }
  }
}
